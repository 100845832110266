<template>
  <div class="chating">
    <div class="header">
      <!-- <div class="title">问题id：{{problemId}}</div> -->
      <div class="title" v-if="problemDetail && problemDetail.problem && problemDetail.problem.status === 'n'">
        <p @click="onBack" v-if="isShowBtn">返回主页</p>
        等待接诊中，平均5分钟内回复…
      </div>
      <div class="title" v-else-if="problemDetail && problemDetail.problem && problemDetail.problem.status === 'c'">
        <p @click="onBack" v-if="isShowBtn">返回主页</p>
        对话已关闭
      </div>
      <div class="title" v-else-if="problemDetail && problemDetail.problem && problemDetail.problem.status === 'p'">
        <p @click="onBack" v-if="isShowBtn">返回主页</p>
        对话已被举报
      </div>
      <div class="title" v-else>
        <p @click="onBack" v-if="isShowBtn">返回主页</p>
        {{number - (problemDetail && problemDetail.problem && problemDetail.problem.interaction)}}次对话后问题关闭
      </div>
      <div class="doc_info" v-if=" problemDetail && problemDetail.problem && problemDetail.problem.status !== 'n' && doctorInfo && isShowDoc" @click="onDetail">
        <img :src="doctorInfo.image" alt="">
        <div class="info">
          <div class="info_right">
            <p>
              <span>{{doctorInfo.name}}</span>
              <span>{{doctorInfo.hospital}}</span>
            </p>
            <p>{{doctorInfo.reply_num}}人咨询</p>
          </div>
          <div class="hospital">
            <p>{{doctorInfo.clinic_name}}</p>
            <p>{{doctorInfo.title}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content" :class="[problemDetail.problem && problemDetail.content.length === 1 ? 'chatings' : '']" id="contentId" ref="content" :style="{'height': chatingHeight}">
      <p class="title">医生回复仅为建议，具体诊疗请前往医院进行</p>
      <div v-for="(items, index) in problemDetail.content" :key="index">
        <div v-if="items.type === 'p'">
          <div class="record_time" v-if="items.time">{{items.time}}</div>
          <div v-for="(item, indx) in items.content" class="user" :key="indx">
            <div class="describe" v-if="item.type === 'text'">{{item.text}}</div>
            <img class="img_icon" :src="itemImg" v-for="itemImg in item.file" :key="itemImg" v-if="item.type === 'image'" @click="onPreview(itemImg)" alt="">
            <!-- <img class="img" src="../assets/img/touxiang.png" alt=""> -->
          </div>
        </div>
        <div v-if="items.type === 'd'">
          <div class="record_time" v-if="items.time">{{items.time}}</div>
          <div v-for="(item, indx) in items.content" :key="indx" class="doctor">
            <img class="img" v-if="doctorInfo.image" :src="doctorInfo.image" alt="">
            <img class="img" v-else src="../assets/img/touxiang.png" alt="">
            <div class="describe" v-if="item.type === 'text'">{{item.text}}</div>
            <img class="img_icon" :src="item.file" v-if="item.type === 'image'" @click="onPreview(item.file)" alt="">
            <!-- <div class="audio" v-if="item.type === 'audio'" @click="playAudio(item)">
              <img src="../assets/img/audio.png"></img>
            </div> -->
            <xaudio v-if="item.type === 'audio'" :src="item.file" />
          </div>
        </div>
      </div>
      <div id="end" ref="end" style="visibility:hidden;height: .7rem;"></div>
    </div>
    <div class="fixed_footer">
      <div v-if=" problemDetail.problem && problemDetail.problem.status === 'c' && problemDetail.problem.star !== -1" class="footer">
        <div class="close">
          <div>问题已自动关闭</div>
          <div>如还需帮助，请在首页提问。</div>
        </div>
      </div>
      <div v-else-if=" problemDetail.problem && problemDetail.problem.status === 'c' && problemDetail.problem.star == -1 && problemDetail.problem.interaction <= 3" class="footer">
        <div class="close">
          <div>问题已自动关闭</div>
          <div>如还需帮助，请在首页提问。</div>
        </div>
      </div>
      <div v-else-if="problemDetail.problem && problemDetail.problem.status === 'p'" class="footer">
        <div class="close">
          <div>问题已被举报</div>
          <div>如还需帮助，请在首页提问。</div>
        </div>
      </div>
      <div v-else-if="problemStatus == 1" class="footer">
        <div class="close">
          因违背提问规则，该问题已关闭。
        </div>
      </div>
      <div v-else-if=" problemDetail.problem && problemDetail.problem.status === 'c' && problemDetail.problem.star == -1" class="footers">
        <div class="close">
          <div>问题已自动关闭</div>
        </div>
        <div class="footer_btn">
          <button class="btn" @click="toEval">评论本次服务</button>
          <button class="btn" @click="toService">继续咨询</button>
        </div>
      </div>
      <div class="footer" v-else>
        <img src="../assets/img/photo_icon.png" alt="" @click="doctorInfo ? upFile() : ''">
        <input type="file" ref="img_file" class="upload_img" style="display:none" multiple @change="getFile">
        <!-- <textarea type="text"> -->
        <textarea class="input" v-model="sendMsg" ref="textarea" :disabled="doctorInfo ? false : true" autofocus placeholder="请输入内容"></textarea>
        <button @click="send">发送</button>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast, ImagePreview } from 'vant';
import { getDate, getTimeText } from '../utils/util';
import xaudio from '@/components/audio';
import { clearInterval, setTimeout } from 'timers';
export default {
  components: {
    xaudio
  },
  data() {
    return {
      sendMsg: '',
      msgArr: [],
      chatingHeight: '',
      height: 0,
      number: 50,
      problemDetail: {},
      problemId: '',
      timers: null,
      isBottom: false,
      doctorInfo: '',
      isShowBtn: true,
      problemStatus: null,
      originalHeight: null,
      isShowDoc: true,
      orderId: null
    }
  },
  created() {
    if (this.$route.query.isShowBtn) {
      this.isShowBtn = false;
    } else {
      this.isShowBtn = true;
    }
    this.number = this.$route.query.number;
    this.docProblemDetail(this.$route.query.problemId, 1);
    this.problemId = this.$route.query.problemId;
    this.orderId = this.$route.query.orderId;
    this.doctorDetail(this.$route.query.doctorId);
    setTimeout(() => {
      this.$refs.end.scrollIntoView(false);
    }, 2000)
  },
  mounted() {
    // this.$nextTick(() => {

    // })
    if (this.problemId) {
      if (this.timers) return
      this.timers = setInterval(() => {
        this.docProblemDetail(this.problemId);
      }, 1000 * 4)
    }
    let _this = this;
    let os = navigator.userAgent;
    _this.originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
    if (os.indexOf('Android') > -1) {
      window.onresize = function () {
        // console.log('rer')
        // var _this = this;
        //键盘弹起与隐藏都会引起窗口的高度发生变化
        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;

        // console.log('键盘弹起==》', _this.originalHeight, windowHeight)
        if (windowHeight - 0 < _this.originalHeight - 0) {  //键盘弹起
          // console.log('键盘弹起进来')
          // setTimeout(() => {
          _this.initHeight(1);
          // }, 1000)
        } else {
          // console.log('键盘收起进来')
          // _this.isShowDoc = true;
          _this.initHeight(2);
        }

      }
    }
    /*  window.onresize = function () {
       // console.log('rer')
       //键盘弹起与隐藏都会引起窗口的高度发生变化
       _this.computedHeight();

     } */
  },
  beforeDestroy() {
    // console.log(this)
    window.clearInterval(this.timers);
  },
  methods: {
    initHeight(type) {
      let _this = this;
      _this.isShowDoc = !_this.isShowDoc;
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      let headerHeight = ''
      let footerHeight = ''
      let end = document.getElementById('end');
      // setTimeout(() => {
      if (type == 1) {
        // headerHeight = document.getElementsByClassName('header')[0].offsetHeight;
        // footerHeight = document.getElementsByClassName('fixed_footer')[0].offsetHeight;
        setTimeout(() => {
          _this.height = windowHeight - 80;
          _this.chatingHeight = windowHeight + 'px';
          let contentId = document.getElementById('contentId')
          // contentId.style.marginBottom = 70 + 'px';
          end.style.height = 70 + 'px';
          _this.$nextTick(() => {
            // _this.$refs.content.children[_this.$refs.content.children.length - 1].scrollIntoView(false);
            _this.$refs.end.scrollIntoView(false);
          })
        }, 500)
        // console.log('页面高度', _this.chatingHeight);
        // })
      } else {
        end.style.height = 0 + 'px';
        headerHeight = document.getElementsByClassName('header')[0].offsetHeight + 80;
        footerHeight = document.getElementsByClassName('fixed_footer')[0].offsetHeight + 40;
        _this.height = windowHeight - headerHeight - footerHeight;
        _this.chatingHeight = windowHeight - headerHeight - footerHeight + 'px';
        _this.$nextTick(() => {
          _this.$refs.content.children[_this.$refs.content.children.length - 1].scrollIntoView(false);
        })
      }

      // let footerHeight = document.getElementsByClassName('fixed_footer')[0].offsetHeight;
      // console.log('底部高度', footerHeight)
      // setTimeout(() => {


      // }, 1000)
      // }, 3000)
      // let content = document.getElementById('contentId');
    },
    onBack() {
      this.$router.push('/home');
    },
    // 问题详情接口
    docProblemDetail(problemId) {
      let params = {
        problem_id: problemId
      }
      this.$http('post', '/cooperation/server/problem/detail', params).then(res => {
        // console.log('问题', res);
        if (res.data.error == 0) {
          this.problemDetail = res.data;
          // 将聊天记录进行格式化
          for (let i = 0; i < this.problemDetail.content.length; i++) {
            this.problemDetail.content[i].content = JSON.parse(this.problemDetail.content[i].content);
            // 判断发送时间是否超过三分钟(180000),超过显示在页面上
            let time = getDate('/', this.problemDetail.content[0].created_time_ms, true);
            this.problemDetail.content[0].time = getTimeText(time);  //格式化时间
            if (i + 1 !== this.problemDetail.content.length) {
              if (this.problemDetail.content[i + 1].created_time_ms - this.problemDetail.content[i].created_time_ms >= 3 * 60 * 1000) {
                let time = getDate('/', this.problemDetail.content[i + 1].created_time_ms, true);
                // this.problemDetail.content[i + 1].time = getTimeText(time);  //格式化时间
                this.$set(this.problemDetail.content[i + 1], 'time', getTimeText(time))
              }
            }
            let item = this.problemDetail.content[i].content;
            for (let k = 0; k < item.length; k++) {
              if (item[k].type === 'image') {
                item[k].file = item[k].file.split(',');
              }
            }
            // if ()
          }
          if (this.problemDetail.doctor.id) {
            this.doctorDetail(this.problemDetail.doctor.id)
          }
          setTimeout(() => {
            // console.log('gaodi========>', document.getElementsByClassName('header')[0])
            this.computedHeight();
            if (this.isBottom) {
              // console.log('自动滚动到底部')
              this.$nextTick(() => {
                this.$refs.end.scrollIntoView(false);
                // this.$refs.content.children[this.$refs.content.children.length - 1].scrollIntoView(false);
                // console.log('滚动前', document.documentElement.scrollTop, document.body.scrollHeight)
                document.documentElement.scrollTop = document.body.scrollHeight;
                document.body.scrollTop = document.body.scrollHeight;
                // console.log('滚动后', document.documentElement.scrollTop, document.body.scrollHeight)
              })
            }
          }, 1000)
          if (this.problemDetail.problem.status === 'c') {
            window.clearInterval(this.timers);
          }
        } else if (res.data.error == 1) {
          this.problemStatus = 1;
        }
      })
    },
    // 医生详情
    doctorDetail(doctorId) {
      let params = {
        doctor_id: doctorId
      }
      this.$http('post', '/cooperation/server/doctor/detail', params).then(res => {
        // console.log('医生', res);
        if (res.data.error == 0) {
          this.doctorInfo = res.data;
        }
      })
    },
    computedHeight() {
      let _this = this;
      //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      let headerHeight = document.getElementsByClassName('header')[0].offsetHeight;
      if (!this.isShowDoc) {
        headerHeight = headerHeight - 30
      }
      let footerHeight = document.getElementsByClassName('fixed_footer')[0].offsetHeight;
      this.height = windowHeight - headerHeight - footerHeight;
      this.chatingHeight = windowHeight - headerHeight - footerHeight + 'px';
      let contentId = document.getElementById('contentId')
      _this = this;
      contentId.onscroll = function () {
        var scrollTop = contentId.scrollTop || contentId.scrollTop;
        var height = contentId.scrollHeight;
        if (Number(_this.height) + 22 + Number(scrollTop) + 100 >= height) {
          // console.log('到底部l', _this.isBottom);
          _this.isBottom = true;
        } else {
          // console.log('没到底部不滚动')
          _this.isBottom = false;
        }
      }
    },
    send() {
      // let contentId = document.getElementById('contentId');
      if (!this.sendMsg) {
        Toast('内容不能为空');
        return;
      }
      let contents = {
        type: 'p'
      }
      let content = [{
        type: 'text',
        text: this.sendMsg
      }]
      // this.talkMsg = '';

      contents.content = content;
      let object = contents;

      let index = this.problemDetail.content.findIndex(item => {
        return item.id === -1;
      })
      // console.log('索引', index)
      if (index > -1) {
        this.problemDetail.content.splice(index, 0, object);
      } else {
        // console.log('push')
        this.problemDetail.content.push(object);
      }
      let params = {
        problem_id: this.problemId,
        content: JSON.stringify(content),
      }
      this.$http('post', '/cooperation/server/problem_content/create', params).then(res => {
        // console.log(res)
        this.docProblemDetail(this.problemId);
      })
      this.sendMsg = '';
      this.$nextTick(() => {
        this.$refs.textarea.focus();
        // console.log(this.$refs.content.children.length, this.$refs.content.children[this.$refs.content.children.length])
        this.$refs.content.children[this.$refs.content.children.length - 1].scrollIntoView(false);
        // console.log('滚动前', document.documentElement.scrollTop, document.body.scrollHeight)
        document.documentElement.scrollTop = document.body.scrollHeight;
        document.body.scrollTop = document.body.scrollHeight;
        // console.log('滚动后', document.documentElement.scrollTop, document.body.scrollHeight)
      })
    },
    // 发送图片
    upFile() {
      this.$refs.img_file.value = "";
      this.$refs.img_file.dispatchEvent(new MouseEvent("click"));
    },
    getFile() {
      let fileArr = [...this.$refs.img_file.files];
      let fd = new FormData();
      fileArr.forEach(item => {
        if (item.type !== "image/jpeg" &&
          item.type !== "image/png" &&
          item.type !== "image/jpg") {
          Toast('"图片只能上传 PNG、JPG、JPEG 格式!"');
          return;
        } else {
          // files.push(item.file);
          fd.append('multipartFiles', item);
        }
      })
      fd.append('businessType', 4);

      this.$http('post', '/server/uploadImg', fd, 1).then(res => {
        // console.log('上传', res);
        if (res.data.code == 200) {
          // this.$store.state.arrImg = this.$store.state.arrImg.concat(res.data.data);
          // // console.log(this.$store.state.arrImg);
          // this.imgArr.push(...res.data.data);
          // // console.log(res.data.data)
          for (let i = 0; i < res.data.data.length; i++) {
            let content = [{
              type: 'image',
              file: res.data.data[i]
            }]
            // console.log('content===>', content)
            let params = {
              problem_id: this.problemId,
              content: JSON.stringify(content),
            }
            this.$http('post', '/cooperation/server/problem_content/create', params).then(res => {
              // console.log(res)
              this.docProblemDetail(this.problemId);
              // _this.$apply();
            })
          }
        }
      })
    },
    // 播放语言
    playAudio(item) {
      let audio = new Audio();//在VUE中使用audio标签
      audio.src = item.file;//设置audio的src为上面生成的url
      // // console.log(audio.ended)
      audio.addEventListener('ended', function () {
        // if ()
        // console.log(audio.ended)
      }, false)
      if (audio.paused) {

        audio.play();//进行播放
      } else {
        audio.pause();// 这个就是暂停
      }
    },
    // 预览
    onPreview(item) {
      ImagePreview({
        images: [
          item
        ],
        onClose() {
        },
      });
    },
    // 进入医生详情
    onDetail() {
      this.$router.push({ path: '/doctor_detail', query: { docType: 2, doctor_id: this.doctorInfo.id, hideBtn: true } })
      // if (item.doctorSource == 2) { // 1：药极客 2：春雨
      //   this.$router.push({ path: '/doctor_detail', query: { docType: item.doctorSource, doctor_id: item.doctorId, dataId: item.id } })
      // } else {
      // }
    },
    toEval() {
      this.$router.push({ path: '/third_eval', query: {orderId: this.orderId, doctor_id: this.doctorInfo.id, doctorInfo: JSON.stringify(this.doctorInfo), problemId: this.problemId } })
    },
    toService() {
      this.$router.push({ path: '/doctor_detail', query: { docType: 2, doctor_id: this.doctorInfo.id } })
    }
  }
}
</script>
<style lang="less" scoped>
.record_time {
  text-align: center;
  margin: 0.2rem 0;
  color: #666666;
  font-size: 0.24rem;
}
.chating {
  height: 100%;
  overflow: hidden;
  background: #f8f8f8;
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    .title {
      width: 100%;
      padding: 0.14rem 0;
      background: #fff;
      color: #39b4af;
      text-align: center;
      font-size: 0.24rem;
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      p {
        float: left;
        color: #39b4af;
        margin-left: 0.2rem;
      }
    }
    .doc_info {
      display: flex;
      align-items: center;
      background: #fff;
      padding: 0.2rem;
      width: calc(100% - 0.4rem);
      img {
        width: 1.14rem;
        height: 1.14rem;
        margin-right: 0.2rem;
      }
      .info {
        width: 100%;
        .info_right {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.2rem;
          align-items: center;
          p:nth-child(1) {
            span:nth-child(1) {
              font-size: 0.32rem;
              color: #333;
              margin-right: 0.2rem;
            }
            span:nth-child(2) {
              font-size: 0.28rem;
              color: #777777;
            }
          }
          p:nth-child(2) {
            font-size: 0.28rem;
            color: #afafaf;
          }
        }
        .hospital {
          display: flex;
          align-items: center;
          p:nth-child(1) {
            width: 1.08rem;
            height: 0.38rem;
            line-height: 0.42rem;
            border: 1px solid #42cec8;
            border-radius: 0.42rem;
            color: #42cec8;
            font-size: 0.24rem;
            text-align: center;
            margin-right: 0.12rem;
          }
          p:nth-child(2) {
            color: #555;
            font-size: 0.26rem;
          }
        }
      }
    }
  }
  .content {
    margin-top: 2rem;
    padding: 0 0.22rem;
    padding-top: 0.22rem;
    width: calc(100% - 0.44rem);
    overflow-y: auto;
    // height: 100%;
    // text-align: center;
    .title {
      padding: 0.08rem 0rem;
      width: 5.68rem;
      background: #cfcccf;
      color: #fff;
      margin: 0 auto;
      margin-top: 0.12rem;
      border-radius: 4px;
      text-align: center;
      font-size: 0.26rem;
    }
    .user {
      display: flex;
      justify-content: flex-end;
      flex-wrap: nowrap;
      align-items: flex-start;
      margin-left: 0.3rem;
      margin-bottom: 0.4rem;
      .describe {
        // width: 4.2rem;
        // height: 1.08rem;
        display: flex;
        align-items: center;
        border-radius: 0.8rem 0.46rem 0rem 0.8rem;
        background: #42cec8;
        border: 1px solid #42cec8;
        padding: 0.16rem 0.43rem;
        // padding-right: 0.3rem;
        font-size: 0.28rem;
        word-break: break-all;
        color: #fff;
        // flex: 1;
      }
      .img {
        height: 1.08rem;
        width: 1.08rem;
        border-radius: 50%;
        margin-left: 0.14rem;
        // flex: 1;
      }
      .img_icon {
        width: 2rem;
        height: 2rem;
        margin-left: 0.2rem;
      }
    }
    .doctor {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-bottom: 0.4rem;
      .describe {
        // width: 4.2rem;
        // height: 1.08rem;
        display: flex;
        align-items: center;
        border-radius: 0.46rem 0.8rem 0.8rem 0rem;
        background: #fff;
        border: 1px solid #42cec8;
        padding: 0.16rem 0.43rem;
        // padding-right: 0.3rem;
        font-size: 0.28rem;
        color: #42cec8;
        word-break: break-all;
      }
      .audio {
        width: 2rem;
        height: 0.44rem;
        border-radius: 0.3rem;
        background: #42cec8;
        text-align: right;
        position: relative;
        img {
          position: absolute;
          top: 0;
          right: 0.1rem;
          text-align: right;
          width: 0.4rem;
          height: 0.4rem;
        }
      }
      .img {
        height: 1.08rem;
        width: 1.08rem;
        border-radius: 50%;
        margin-right: 0.14rem;
      }
      .img_icon {
        width: 2rem;
        height: 2rem;
        // margin-left: 0.2rem;
      }
    }
  }
  .chatings {
    margin-top: 0.86rem;
  }
  .fixed_footer {
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0.3rem 0.2rem;
    width: calc(100% - 0.4rem);
  }
  .footer {
    display: flex;
    align-items: center;

    // .upload_img {
    //   position: absolute;
    //   left: 0;
    // }
    .close {
      background: #ffffff;
      // padding:20rpx;
      font-size: 0.24rem;
      view:nth-child(1) {
        font-weight: bold;
      }
      view:nth-child(2) {
        color: #666666;
      }
    }
    .footer_btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 0.2rem;
    }
    img {
      width: 0.56rem;
      height: 0.46rem;
      margin-right: 0.32rem;
    }
    .input {
      //   width: 5rem;
      border-radius: 0.8rem;
      height: 0.36rem;
      flex: 1;
      margin-right: 0.34rem;
      background: #f5f7f8;
      padding: 0.16rem 0.3rem;
      border: 0;
    }
    .input::-webkit-input-placeholder {
      color: #b5b5b5;
      font-size: 0.3rem;
    }
    button {
      background: #42cec8;
      border: 0;
      font-size: 0.28rem;
      color: #fff;
      padding: 0.12rem 0.3rem;
      border-radius: 4px;
    }
  }
  .footers {
    .footer_btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 0.2rem;
    }
    button {
      background: #42cec8;
      border: 0;
      font-size: 0.28rem;
      color: #fff;
      padding: 0.12rem 0.3rem;
      border-radius: 4px;
    }
  }
}
</style>
